import { GatsbySeo } from 'gatsby-plugin-next-seo'

import Circula from '../../components/Circula/Circula'

function Page() {
  return (
    <>
      <GatsbySeo language="pt-br" noindex nofollow />
      <Circula />
    </>
  )
}

export default Page
